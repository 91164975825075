<template>
  <v-container fluid>
    <Header headerClass="qualita-aria" title="Qualità dell'aria" />
    <v-container page id="contenuto">
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Effetti sulla salute e sull’ambiente</h2>
          <div class="w-80">
            <p class="mt-40">
              L’inquinamento atmosferico è stato classificato tra i cancerogeni
              umani dall’Agenzia Internazionale per la Ricerca sul Cancro
              dell’OMS.<br />
              Ogni anno nell'UE più di
              <strong>400.000 persone muoiono prematuramente</strong> a causa
              delle conseguenze dell'inquinamento atmosferico: sono 10 volte le
              vittime degli incidenti stradali. Ben 6,5 milioni di persone si
              ammalano, a causa dell'inquinamento atmosferico, di patologie come
              ictus, asma e bronchite.<br />
              L'inquinamento
              <strong>danneggia anche l’ambiente naturale</strong>, incidendo
              sia sulla vegetazione che sulla fauna selvatica: quasi i due terzi
              degli ecosistemi europei sono minacciati dai suoi effetti.
            </p>
            <p class="mt-40">
              Il rischio sanitario legato alle sostanze presenti nell’aria
              dipende, oltre che dalla loro concentrazione, anche dalla
              <strong>dimensione delle particelle</strong> che le compongono:
              quelle di dimensioni minori sono un pericolo maggiore, poiché
              possono penetrare più in profondità nell’apparato respiratorio.
            </p>
            <p class="mt-40">In prima approssimazione:</p>
            <ul class="lista">
              <li>
                le particelle con diametro superiore ai 10 μm si fermano nelle
                prime vie respiratorie
              </li>
              <li>
                le particelle con diametro tra i 5 e i 10 μm raggiungono la
                trachea e i bronchi
              </li>
              <li>
                le particelle con diametro inferiore ai 5 μm possono raggiungere
                gli alveoli polmonari.
              </li>
            </ul>
            <p class="mt-5">
              Gli studi epidemiologici hanno mostrato una correlazione tra le
              concentrazioni di polveri in aria e la manifestazione di
              <strong>malattie croniche alle vie respiratorie</strong>, in
              particolare asma, bronchiti, enfisemi. A livello di effetti
              indiretti, inoltre, il particolato agisce da veicolo per sostanze
              ad elevata tossicità, quali ad esempio gli idrocarburi policiclici
              aromatici.
            </p>
            <p class="mt-40">
              <strong>
                FONTE:
                <a
                  href="https://www.eea.europa.eu/ds_resolveuid/FBR5J0K69U"
                  target="_blank"
                  title="Questo link si apre in una nuova scheda"
                  >EEA
                </a>
              </strong>
            </p>
          </div>
          <v-container>
            <v-row>
              <v-col width="30%">
                <div class="text-center effetti-salute">
                  <img
                    src="../../assets/stylesheets/im/global.png"
                    aria-hidden="true"
                    alt="Mondo"
                  />
                  <p>
                    <strong>25%</strong><br />di tutte le malattie nel mondo è
                    dovuto all’esposizione a fattori ambientali
                  </p>
                  <p class="small">Fonte: who</p>
                </div>
              </v-col>
              <v-col>
                <div class="text-center effetti-salute">
                  <img
                    src="../../assets/stylesheets/im/mask.png"
                    aria-hidden="true"
                    alt="Mascherina"
                  />
                  <p>
                    Popolazione esposta ai superamenti dei valori limite per gli
                    inquinanti:
                  </p>
                  <p>
                    PM10 45,7% (Valore Limite giornaliero per la protezione
                    della salute umana di 50 μg/m3 da non superare più di 35
                    volte/anno)
                  </p>
                  <p>
                    NO2 20% (Valore limite annuale per la protezione della
                    salute umana di 40 μg/m3)
                  </p>
                  <p class="small">Fonte: ARPA Piemonte, anno 2019</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-center effetti-salute">
                  <img
                    src="../../assets/stylesheets/im/economy.png"
                    aria-hidden="true"
                    alt="Perdita economica"
                  />
                  <p>
                    <strong>88</strong><br />miliardi di euro è la perdita
                    economica dovuta all’inquinamento atmosferico (5.7% del PIL
                    italiano)
                  </p>
                  <p class="small">Fonte: WHO, OCSE 2015</p>
                </div>
              </v-col>
              <v-col>
                <div class="text-center effetti-salute">
                  <img
                    src="../../assets/stylesheets/im/diagnose.png"
                    aria-hidden="true"
                    alt="Morti premature"
                  />
                  <p>
                    <strong>84.400</strong><br />morti premature in Italia
                    attribuibili all’esposizione a particolato sottile (PM2,5),
                    ozono (O<small>3</small>) e biossido di azoto
                    (NO<small>2</small>)
                  </p>
                  <p class="small">Fonte: Agenzia Europea dell’Ambiente 2016</p>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-tabs
            :color="tabColori"
            v-model="tabFonti"
            icons-and-text
            grow
            next-icon="fas fa-chevron-right"
            prev-icon="fas fa-chevron-left"
            show-arrows
            class="mt-40 v-tab-cont tab-custom"
          >
            <v-tab active>
              PM
              <v-icon x-large>$vuetify.icons.pmIcon</v-icon>
              <span class="sr-only"
                >Può arrivare in profondità nei polmoni e causare gravi problemi
                di salute, come asma aggravata, bronchite e diminuzione della
                funzione polmonare. L'esposizione a lungo termine è legata a
                decessi cardiovascolari e respiratori, aumento della malattia e
                malattie respiratorie infantili. Il PM è anche associato ad un
                aumento dei casi di cancro, in particolare il cancro ai polmoni.
                Può portare a difetti congeniti. Può portare a malattie
                respiratorie infantili.</span
              >
            </v-tab>

            <v-tab>
              <span>SO<small>2</small></span>
              <v-icon x-large>$vuetify.icons.soIcon</v-icon>
              <span class="sr-only"
                >Può causare mal di testa, malessere generale e ansia. Aggravata
                l'asma e può ridurre la funzione polmonare e infiammare le vie
                respiratorie.</span
              >
            </v-tab>

            <v-tab>
              <span>NO<small>x</small></span>
              <v-icon x-large>$vuetify.icons.noIcon</v-icon>
              <span class="sr-only"
                >Può influenzare il fegato, i polmoni, la milza e il sangue. Può
                aggravare le malattie polmonari che portano a sintomi
                respiratori e ad una maggiore suscettibilità alle infezioni
                respiratorie.</span
              >
            </v-tab>

            <v-tab>
              <span>NH<small>3</small></span>
              <v-icon x-large>$vuetify.icons.nhIcon</v-icon>
              <span class="sr-only"
                >L'esposizione a livelli elevati di ammoniaca può irritare la
                pelle, gli occhi, la gola e i polmoni e causare tosse. Le
                persone affette da asma possono essere più sensibili
                all'ammoniaca respiratoria rispetto ad altri.</span
              >
            </v-tab>

            <v-tab>
              COV
              <v-icon x-large>$vuetify.icons.covIcon</v-icon>
              <span class="sr-only"
                >Alcuni COV possono causare mal di testa, vertigini, disturbi
                visivi e disturbi della memoria. Alcuni COV sono
                cancerogeni.</span
              >
            </v-tab>

            <v-tab>
              O3
              <v-icon x-large>$vuetify.icons.o3Icon</v-icon>
              <span class="sr-only"
                >Livelli elevati possono causare problemi respiratori, ridurre
                la funzione polmonare e scatenare l'asma.</span
              >
            </v-tab>

            <v-tab-item>
              <v-container fluid>
                <v-row class="flex" justify="space-between">
                  <v-col cols="12">
                    <v-tooltip v-model="sistema_nervoso_b" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-t"></div>
                        </v-btn>
                      </template>
                      <span>Colpisce il sistema nervoso centrale.</span>
                    </v-tooltip>
                    <v-tooltip v-model="polmoni_b" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-t"></div>
                        </v-btn>
                      </template>
                      <span>
                        Può arrivare in profondità nei polmoni e causare gravi
                        problemi di salute, come asma aggravata, bronchite e
                        diminuzione della funzione polmonare. L'esposizione a
                        lungo termine è legata a decessi cardiovascolari e
                        respiratori, aumento della malattia e malattie
                        respiratorie infantili. Il PM è anche associato ad un
                        aumento dei casi di cancro, in particolare il cancro ai
                        polmoni.
                      </span>
                    </v-tooltip>
                    <v-tooltip v-model="difetti_congeniti_b" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-t"></div>
                        </v-btn>
                      </template>
                      <span>Può portare a difetti congeniti.</span>
                    </v-tooltip>
                    <v-tooltip v-model="infantili_b" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-t"></div>
                        </v-btn>
                      </template>
                      <span>
                        Può portare a malattie respiratorie infantili.
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row class="flex" justify="space-between">
                  <v-col cols="12">
                    <v-tooltip v-model="uno_g" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-g"></div>
                        </v-btn>
                      </template>
                      <span
                        >Può causare mal di testa, malessere generale e
                        ansia.</span
                      >
                    </v-tooltip>
                    <v-tooltip v-model="due_g" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-g"></div>
                        </v-btn>
                      </template>
                      <span
                        >Aggravata l'asma e può ridurre la funzione polmonare e
                        infiammare le vie respiratorie.</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row class="flex no" justify="space-between">
                  <v-col cols="12">
                    <v-tooltip v-model="uno_v" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-v"></div>
                        </v-btn>
                      </template>
                      <span
                        >Può influenzare il fegato, i polmoni, la milza e il
                        sangue. Può aggravare le malattie polmonari che portano
                        a sintomi respiratori e ad una maggiore suscettibilità
                        alle infezioni respiratorie.</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row class="flex nh3" justify="space-between">
                  <v-col cols="12">
                    <v-tooltip v-model="uno_f" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-f"></div>
                        </v-btn>
                      </template>
                      <span
                        >L'esposizione a livelli elevati di ammoniaca può
                        irritare la pelle, gli occhi, la gola e i polmoni e
                        causare tosse. Le persone affette da asma possono essere
                        più sensibili all'ammoniaca respiratoria rispetto ad
                        altri.</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row class="flex cov" justify="space-between">
                  <v-col cols="12">
                    <v-tooltip v-model="uno_r" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-r"></div>
                        </v-btn>
                      </template>
                      <span
                        >Alcuni COV possono causare mal di testa, vertigini,
                        disturbi visivi e disturbi della memoria.</span
                      >
                    </v-tooltip>
                    <v-tooltip v-model="due_r" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-r"></div>
                        </v-btn>
                      </template>
                      <span>Alcuni COV sono cancerogeni.</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row class="flex o3" justify="space-between">
                  <v-col cols="12">
                    <v-tooltip v-model="uno_b" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <div class="indicator-b"></div>
                        </v-btn>
                      </template>
                      <span
                        >Livelli elevati possono causare problemi respiratori,
                        ridurre la funzione polmonare e scatenare l'asma.</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
          <p>
            <small
              >NOTA: Gli effetti indicati sul profilo maschile sono da
              intendersi validi per tutti gli esseri umani indipendentemente da
              età o genere. Nel profilo femminile sono evidenziati gli effetti
              in gravidanza. Nel profilo del bambino sono evidenziati gli
              effetti per i più piccoli</small
            >
          </p>
          <v-card class="c-right" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h4>Inquinamento dell’aria e la salute dei bambini</h4>
                <p class="mt-40">
                  Un’ampia letteratura scientifica evidenzia la maggior
                  vulnerabilità all’inquinamento atmosferico dei bambini
                  rispetto agli adulti.<br />
                  I dati rilevati pongono il nostro Paese in una situazione di
                  forte criticità:
                  <strong
                    >il 98% dei bambini sotto i 5 anni vive in aree dove le
                    concentrazioni di PM2.5 sono al di sopra dei livelli
                    raccomandati dall’Oms</strong
                  >
                  per la tutela della salute.
                  <br />
                  Tra i principali <strong>effetti</strong> dell’inquinamento
                  sui bambini sono segnalati, oltre a una ridotta funzione
                  polmonare: asma, infezioni acute delle basse vie respiratorie,
                  problemi nello sviluppo neurocomportamentale, obesità e otite.
                  Anche alcuni tumori infantili possono essere associati a una
                  prolungata esposizione della madre agli inquinanti cancerogeni
                  nel periodo prenatale.<br />
                  Adottare <strong>azioni di prevenzione</strong> durante la
                  fase critica infantile-adolescenziale può produrre immensi
                  benefici per la salute pubblica in termini di riduzione del
                  carico di patologie e costi sanitari.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <div class="w-80">
            <p class="mt-40">
              Anche altri inquinanti atmosferici possono causare gravi danni
              alla salute umana e all’ambiente.
            </p>
            <p>
              Questi includono metalli pesanti (come mercurio, arsenico, piombo,
              cadmio e nichel) e idrocarburi policiclici aromatici (come il
              benzoapirene).
            </p>
            <p>
              La legislazione esistente ha già contribuito a ridurre
              significativamente le emissioni di questi inquinanti, con un
              conseguente notevole diminuzione di rischio per la salute.
            </p>
          </div>
          <v-card class="c-left" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <p>
                  <strong>
                    In che modo le politiche europee, nazionali e regionali
                    possono migliorare la salute, l’economia e l’ambiente?
                  </strong>
                </p>
                <p>
                  Il costo totale stimato per attuare le politiche sull'aria
                  pulita è intorno a 2,2 miliardi di euro l'anno, fino al 2030.
                  Tuttavia, circa 3,3 miliardi di euro all'anno potrebbero
                  essere risparmiati in costi diretti altrimenti causati
                  dall'inquinamento atmosferico, oltre a un ulteriore 40-140
                  miliardi di euro di costi indiretti (ad esempio, relativi a un
                  generale miglioramento della salute). Ciò significa che
                  <strong>
                    i benefici attesi per la società sono oltre 20 volte i costi
                    di attuazione della legislazione.
                  </strong>
                </p>
                <p class="mt-40">
                  <strong>FONTE:</strong> Commissione Europea<br />
                  Quanto riportato sono i risutati attesi dall'attuazione del
                  Clean Air Policy Package.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <!--a href="/politiche/stili" class="v-btn primary">
            <span class="v-btn__content"> PER APPROFONDIMENTI </span>
          </a-->
          <router-link class="v-btn primary" :to="{ name: 'stili' }">
            <span class="v-btn__content"> PER APPROFONDIMENTI </span>
          </router-link>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuQualitaAria page="effetti-salute" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuQualitaAria from "@/views/qualita-aria/MenuQualitaAria";

export default {
  components: {
    Header,
    MenuQualitaAria
  },
  data() {
    return {
      tabFonti: 0,
      show: false,
      sistema_nervoso_b: false,
      polmoni_b: false,
      difetti_congeniti_b: false,
      infantili_b: false,
      uno_g: false,
      due_g: false,
      uno_v: false,
      uno_f: false,
      uno_r: false,
      due_r: false,
      uno_b: false
    };
  },
  computed: {
    tabColori: function () {
      let colore = "";
      switch (this.tabFonti) {
        case 0:
          colore = "#07b6d7";
          break;
        case 1:
          colore = "#E4CC0C";
          break;
        case 2:
          colore = "#39AE8B";
          break;
        case 3:
          colore = "#C049BC";
          break;
        case 4:
          colore = "#ec0464";
          break;
        case 5:
          colore = "#044c7c";
          break;
        case 6:
          colore = "#e4a434";
          break;
        default:
          break;
      }
      return colore;
    }
  }
};
</script>
